<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{
            labelBtn != "Salvar"
              ? "Editar Departamento"
              : "Cadastrar Departamento"
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                label="ID"
                v-model="formDepto.id_depto"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                label="Departamento"
                v-model="formDepto.departamento"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                label="Ativo"
                v-model="formDepto.ativo"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar'
              ? editarDepartamento()
              : cadastrarDepartamento()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";
export default {
  name: "ModalCadMenu",

  mixins: [rules],

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    editDepartamento: {
      type: Object
    }
  },

  data() {
    return {
      itemsModulo: [],
      formDepto: {
        departamento: "",
        ativo: ""
      },
      defaultState: {
        departamento: "",
        ativo: ""
      }
    };
  },

  watch: {
    editDepartamento() {
      if (this.editDepartamento) {
        this.formDepto = this.editDepartamento;
      }
    }
  },

  methods: {
    async cadastrarDepartamento() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .departamentos()
          .store(this.formDepto, {
            notification: true,
            message: "Departamento cadastrado com sucesso!"
          });
        this.close();
      } else {
        this.$notify({
          notification: true,
          message: "Formulário inválido"
        });
      }
    },

    async editarDepartamento() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .departamentos(this.formDepto.id_depto, this.formDepto)
          .update(this.formDepto, {
            notification: true,
            message: "Menu Editado com sucesso!"
          });
        this.$notify({
          type: "success",
          text: "Indicador editado"
        });
        this.close();
      } else {
        this.$notify({
          notification: true,
          message: "Formulário inválido"
        });
      }
    },

    close() {
      this.formDepto = this.defaultState;
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>

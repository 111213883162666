<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoDepartamento"
      :loading="loading"
      :paginate="true"
      sort-by="id_band"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Departamento</v-toolbar-title>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="242"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="formatStatus(item.ativo) === 'Ativo' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.ativo) }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="243"
          icon
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->

        <!-- <v-btn
          v-can-access="1402"
          icon
          @click="vinculaUsuario(item)"
          class="ml-2"
          color="primary"
          dark
        >
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>-->
        <!-- <v-btn
          v-can-access="245"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        -->
        <IconBottom :name="'edit'" v-can-access="243" @click="editItem(item)" />
        <IconBottom
          :name="'bond'"
          v-can-access="1402"
          @click="vinculaUsuario(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="245"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadDepartamento
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editDepartamento="departamento"
      @close="dialog = false"
    />
    <DraggableColabDepto
      :dialog="dialogUsuarioColab"
      :dadosDepto="departamento"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import ModalCadDepartamento from "./ModalCadDepartamento.vue";
import DraggableColabDepto from "./DraggableColabDepto.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import comissao from "@/services/http/comissaoService";

export default {
  name: "TabelaDepartamento",

  components: {
    BaseTable,
    ModalCadDepartamento,
    DraggableColabDepto,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      dialogUsuarioColab: false,
      labelBtn: "Salvar",
      departamento: {},
      search: "",
      headers: [
        { text: "ID", value: "id_depto" },
        { text: "Departamento", value: "departamento" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoDepartamento: []
    };
  },

  methods: {
    async buscaDepartamento() {
      try {
        let {
          data: { data }
        } = await comissao()
          .departamentos()
          .show({ per_page: -1, status: "S" });
        this.infoDepartamento = data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao buscar departamento!"
        });
      }
    },
    refresh() {
      this.dialogUsuarioColab = false;
      this.buscaDepartamento();
    },

    editItem(item) {
      this.departamento = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    vinculaUsuario(item) {
      this.departamento = Object.assign({}, item);
      this.dialogUsuarioColab = true;
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "deletar Departamento",
          `Deseja deletar o departamento ${item.departamento}?`
        );

        comissao()
          .departamentos(item.id_depto)
          .delete(
            {},
            {
              notification: "success",
              menssage: "Departamento deletado"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Departamento"
        });
      } finally {
        this.buscaDepartamento();
      }
    },
    formatStatus(item) {
      if (item == "A") item = "Ativo";
      if (item == "N") item = "Inativo";
      return item;
    },

    async refetch() {
      (this.dialog = false), await this.buscaDepartamento();
    }
  },

  mounted() {
    this.buscaDepartamento();
  }
};
</script>

<style></style>
